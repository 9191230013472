import { MissionTheme, Font } from '@root/interfaces';
import globals from '../default';

const colors = {
  colorPrimary: '#ec4d30',
  colorSecondary: '#1b3a7a',
  colorTertiary: '#1b3a7a',
  colorText: '#7c7c7c',
  colorBackground: '#1b3a7a',
};

const primaryFont: Font = {
  name: 'Whitney',
  fontFamily: 'WhitneySSm-Book, sans-serif',
};

const secondaryFont: Font = {
  name: 'Whitney',
  fontFamily: 'WhitneySSm-Semibold, sans-serif',
};

const theme: MissionTheme = {
  ...globals,
  ...colors,
  clientFullName: 'United Way of King County',
  isSVG: false,
  isDisableFullHeader: false,
  extraBoldFrequency: false,
  useSecThemeColor: true,
  addCustomColor: false,
  headerBackgroundColor: 'white',
  headerTitleColor: '#7c7c7c',
  headerLogoHeight: 103,
  primaryFont: primaryFont,
  headingFont: secondaryFont,
  headingFontSize: '1.5rem',
  headingBold: false,
  subHeadingFontSize: '1rem',
  buttons: {
    ...globals.buttons,
    defaults: {
      ...globals.buttons.defaults,
      background: colors.colorPrimary,
    },
  },
  navButtons: {
    left: {
      ...globals.buttons,
      defaults: {
        ...globals.buttons.defaults,
        background: colors.colorPrimary,
      },
    },
    right: {
      ...globals.buttons,
      defaults: {
        ...globals.buttons.defaults,
        background: colors.colorPrimary,
      },
    },
  },
  globalFontSize: 14,
};

export default theme;
