import * as React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useSelector } from 'react-redux';
import Campaign from '@modules/campaign';
import User from '@modules/user';
import Event from '@modules/event';
import Donation from '@modules/donation';
import { RootState } from '@app/RootState';
import { DonationPageType } from '@root/enums';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// Add Tras-cur
export const TransactionCurrency = React.createContext({
  currencySymbol: '$',
  isoCurrencyCode: 'CAD',
  languageCode: 'en-CA',
});

const history = createBrowserHistory();
const localEnv =
  window.location.host === 'localhost:3000' ||
  window.location.host === 'localhost:5011';

function App() {
  const { isLoading: appLoading, sentryKey } = useSelector(
    (state: RootState) => state.app
  );

  if (!localEnv && sentryKey) {
    const startOfKey = sentryKey.substring(0, sentryKey.lastIndexOf('/'));

    const endOfKey = sentryKey.substring(
      sentryKey.lastIndexOf('/'),
      sentryKey.lastIndexOf('/') + sentryKey.length
    );

    Sentry.init({
      dsn: `https://${startOfKey}@o866051.ingest.sentry.io${endOfKey}`,
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
      ],
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
    });
  }

  return appLoading ? null : (
    <Router history={history}>
      <Switch>
        <Route path="/user" component={User} />
        <Route path="/campaign/:friendlyUrl+" component={Campaign} />
        <Route path="/donation/:friendlyUrl" kind="DonationPage">
          <Donation donationPageType={DonationPageType.DP} />
        </Route>
        <Route path="/donationCaller/:friendlyUrl" kind="DonationPage">
          <Donation donationPageType={DonationPageType.OCP} />
        </Route>
        <Route path="/event/:friendlyUrl" component={Event} />
      </Switch>
    </Router>
  );
}

export default App;
